<template>
    <form :class="{ 'touched': formSubmitted }" @submit.prevent="formSubmit">
        <h4 class="mb-2">{{ $t('checkout.info_person_info_title') }}</h4>

        <!-- salutation -->
        <div class="field">
            <label for="inputSalutation" class="form-label">{{ $t('account.label_salutation') }}</label>

            <div class="form-check" v-for="salutation in salutations" :key="salutation.id">
                <input
                    class="form-check-input"
                    type="radio"
                    name="inputSalutation"
                    :id="salutation.id"
                    :value="salutation.id"
                    v-model="salutationId"
                    required
                />
                <label class="form-check-label" :for="salutation.id">
                    {{ salutation.translated.displayName }}
                </label>
            </div>
        </div>
        <!-- end salutation -->

        <!-- first name + last name -->
        <div class="row gy-1 gx-2 gx-md-2 gx-xl-3">

            <div class="col-12 col-md-6">
                <div class="field">
                    <label for="inputFirstName" class="form-label">{{ $t('account.label_first_name') }}</label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        id="inputFirstName"
                        autocomplete="given-name"
                        v-model="firstName"
                        required
                    />
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="field">
                    <label for="inputLastName" class="form-label">{{ $t('account.label_last_name') }}</label>
                    <input
                        type="text"
                        class="form-control form-control-lg"
                        id="inputLastName"
                        autocomplete="family-name"
                        v-model="lastName"
                        required
                    />
                </div>
            </div>

        </div>
        <!-- end first name + last name -->

        <!-- email + phone -->
        <div class="row gy-1 gx-2 gx-md-2 gx-xl-3">

            <div class="col-12 col-md-6">
                <div class="field m-0">
                    <label for="inputEmail" class="form-label">{{ $t('account.label_email') }}</label>
                    <input
                        type="email"
                        class="form-control form-control-lg"
                        id="inputEmail"
                        autocomplete="email"
                        v-model="email"
                        :disabled="alreadyRegistered"
                        required
                    />
                </div>
            </div>

            <div class="col-12"></div>

            <div class="col-12 col-md-6" v-if="config('showPhoneNumberField', false)">
                <div class="field m-0">
                    <label for="phone" class="form-label">{{ $t('account.label_phone') }}</label>
                    <input
                        type="tel"
                        class="form-control form-control-lg"
                        id="inputPhone"
                        v-model="phoneNumber"
                        pattern="^[0-9\s\-\(\)\.]+$"
                        :required="config('phoneNumberFieldRequired', false)"
                    />
                </div>
            </div>

        </div>
        <!-- end email + phone -->

        <hr class="my-3" />

        <CustomerAddress
            :street.sync="street"
            :zipcode.sync="zipcode"
            :city.sync="city"
            :countryId.sync="countryId"
            :countries="countries"
            :showNameFields="false"
        />

        <div v-if="deliveryDifferentAsBilling">
            <CustomerAddress
                class="pt-3"
                :addressLabel="$t('checkout.info_delivery_info_title')"
                :firstName.sync="deliveryAddressFirstName"
                :lastName.sync="deliveryAddressLastName"
                :street.sync="deliveryStreet"
                :zipcode.sync="deliveryZipcode"
                :city.sync="deliveryCity"
                :countryId.sync="deliveryCountryId"
                :countries="countries"
                :showNameFields="true"
            />
        </div>

        <div class="field">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkboxDiffrentAddresses"
                    v-model="deliveryDifferentAsBilling"
                />
                <label class="form-check-label small" for="checkboxDiffrentAddresses">
                    {{ $t('checkout.field_check_delivery_different_as_billing') }}
                </label>
            </div>
        </div>

        <template v-if="!alreadyRegistered">
            <hr class="mb-3" />

            <h4 class="mb-2">{{ $t('checkout.info_storing_data_title') }}</h4>
            <p>{{ $t('checkout.storing_data_text') }}</p>

            <!-- account -->
            <div class="field" v-if="checkout">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="account"
                        v-model="createAccount"
                    />
                    <label class="form-check-label" for="account">
                        {{ $t('checkout.field_create_account') }}
                    </label>
                </div>
            </div>
            <!-- end account -->

            <!-- password -->
            <div class="row gy-1 gx-2 gx-md-2 gx-xl-3 mb-2" v-if="createAccount">
                <div class="col-12 col-md-6">
                    <div :class="['field m-0', passwordsMatchInvalid || passwordLengthInvalid ? 'required' : '']">
                        <label for="inputPassword" class="form-label">{{ $t('account.label_password') }}</label>
                        <input
                            type="password"
                            class="form-control form-control-lg"
                            id="inputPassword"
                            autocomplete="new-password"
                            v-model="password"
                            :minlength="passwordMinLength"
                            :placeholder="$t('account.label_password_placeholder')"
                            required
                        />

                        <span v-if="passwordLengthInvalid" class="error-message small text-danger">
                            {{ $t('error.password_length') }}
                        </span>
                    </div>
                </div>

                <div class="col-12"></div>

                <div class="col-12 col-md-6">
                    <div class="field">
                        <label for="inputPasswordConfirm" class="form-label">{{ $t('account.label_confirm_password') }}</label>
                        <input
                            type="password"
                            class="form-control form-control-lg"
                            id="inputPasswordConfirm"
                            autocomplete="new-password"
                            v-model="passwordConfirm"
                            :minlength="passwordMinLength"
                            required
                        />

                        <span v-if="passwordsMatchInvalid" class="error-message small text-danger">
                            {{ $t('error.passwords_must_match') }}
                        </span>
                    </div>
                </div>
            </div>
            <!-- end password -->

            <!-- gdpr -->
            <div class="field">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="acceptGdpr"
                        v-model="acceptedDataProtection"
                        :required="config('requireDataProtectionCheckbox', true)"
                    />
                    <label class="form-check-label" for="acceptGdpr">
                        {{ $t('account.checkbox_gdpr_accept_policy') }}
                    </label>
                </div>
            </div>
            <!-- end gdpr -->

            <!-- newsletter -->
            <!--
            <div class="field">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="acceptNewsLetter" v-model="acceptNewsLetter">
                    <label class="form-check-label" for="acceptNewsLetter">
                        {{ $t('account.checkbox_accept_neweletter') }}
                    </label>
                </div>
            </div>
            -->
            <!-- end newsletter -->
        </template>

        <!-- BE errors  -->
        <div v-if="beErrors?.length > 0" class="error-message small text-danger">
            <ul class="list-unstyled">
                <li v-for="error in beErrors">
                    <p class="small text-danger" v-html="error"></p>
                </li>
            </ul>
        </div>
        <!-- end BE errors -->

        <button class="btn btn-primary w-100" @click="formSubmitted = true">
            <span v-if="isLoading" class="spinner-border" role="status" aria-hidden="true"></span>
            <span v-if="!isLoading">
                {{ alreadyRegistered || checkout ? $t('checkout.btn_next_step') : $t('account.btn_register') }}
            </span>
        </button>
    </form>
</template>

<script>
import shopware from '../../mixins/shopware';
import customer from '../../mixins/customer';

import CustomerAddress from './CustomerAddress.vue';

import { EventBus } from "../../api/eventBus.ts";

export default {
    mixins: [shopware, customer],
    components: {
        CustomerAddress
    },
    props: {
        checkout: Boolean,
        btnLink: String,
        loginLink: String,
        forgotPasswordLink: String
    },
    data() {
        return {
            isLoading: false,
            formSubmitted: false,

            // fields
            createAccount: true,
            salutationId: null,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            acceptedDataProtection: false,
            acceptNewsLetter: false,
            deliveryDifferentAsBilling: false,
            street: '',
            zipcode: '',
            city: '',
            countryId: null,
            phoneNumber: '',
            deliveryAddressFirstName: '',
            deliveryAddressLastName: '',
            deliveryStreet: '',
            deliveryZipcode: '',
            deliveryCity: '',
            deliveryCountryId: null,

            // api
            beErrors: [],
        };
    },
    computed: {
        alreadyRegistered() {
            return this.context && this.context.customer;
        },
        passwordMinLength() {
            return this.config('passwordMinLength', 8);
        },
        passwordLengthInvalid() {
            return this.password && this.password.length < this.passwordMinLength;
        },
        passwordsMatchInvalid() {
            return this.password && this.passwordConfirm && this.password !== this.passwordConfirm;
        }
    },
    created: function () {
        this.init();
    },
    methods: {
        async init() {
            await this.initContext();

            if (this.checkout) {
                this.createAccount = this.config('createCustomerAccountDefault', false);
            }

            const promises = [
                this.getSalutations(),
                this.getCountries(),
            ];
            await Promise.all(promises);

            this.initProfile();
        },

        initProfile() {
            this.setDefaultCountry();

            const customer = this.context.customer;

            if (customer) {
                this.salutationId = customer.salutationId;
                this.firstName = customer.firstName;
                this.lastName = customer.lastName;
                this.email = customer.email;

                if (customer.activeBillingAddress?.id) {
                    this.street = customer.activeBillingAddress.street;
                    this.zipcode = customer.activeBillingAddress.zipcode;
                    this.city = customer.activeBillingAddress.city;
                    this.countryId = customer.activeBillingAddress.countryId;
                    this.phoneNumber = customer.activeBillingAddress.phoneNumber;
                }

                if (customer.activeBillingAddress?.id !== customer.activeShippingAddress?.id) {
                    this.deliveryDifferentAsBilling = true;
                    this.deliveryAddressFirstName = customer.activeShippingAddress.firstName;
                    this.deliveryAddressLastName = customer.activeShippingAddress.lastName;
                    this.deliveryStreet = customer.activeShippingAddress.street;
                    this.deliveryZipcode = customer.activeShippingAddress.zipcode;
                    this.deliveryCity = customer.activeShippingAddress.city;
                    this.deliveryCountryId = customer.activeShippingAddress.countryId;
                }
            }
        },

        setDefaultCountry() {
            const iso = this.api.getCookie('sw-iso-639-3166').split('-')[1];
            const isoCountry = this.countries.find(country => country.iso === iso);
            const defaultCountryId = (isoCountry) ? isoCountry.id : this.context.salesChannel.countryId;

            this.countryId = defaultCountryId
            this.deliveryCountryId = defaultCountryId;
        },

        async formSubmit() {
            this.isLoading = true;
            this.formSubmitted = true;

            this.beErrors = [];

            try {
                if (this.alreadyRegistered) {
                    await this.updateCustomer();
                } else {
                    await this.register();
                }

                if (this.btnLink) {
                    window.location.href = this.btnLink;
                    return;
                }
            } catch (error) {
                const params = {
                    loginLink: this.loginLink,
                    forgotPasswordLink: this.forgotPasswordLink
                }

                this.beErrors = this.getErrorMessages(error, params);

                if (this.beErrors.length == 0) {
                    EventBus.$emit('trigger-alert', {
                        message: this.$t('error.generic'),
                        state: 'error'
                    });
                }
            } finally {
                this.isLoading = false;
            }
        },

        async updateCustomer() {
            try {
                const customer = this.context.customer;
                const { firstName, lastName, salutationId } = this;
                const { street, zipcode, city, countryId, phoneNumber } = this;

                await this.updateCustomerProfile({
                    firstName,
                    lastName,
                    salutationId
                });
                await this.updateCustomerAddress(customer.activeBillingAddress.id, {
                    street,
                    zipcode,
                    city,
                    countryId,
                    firstName,
                    lastName,
                    phoneNumber
                });

                if (this.deliveryDifferentAsBilling) {
                    const shippingAddressId = this.findShippingSpecificAddressId(customer);
                    const shippingAddressData = {
                        street: this.deliveryStreet,
                        zipcode: this.deliveryZipcode,
                        city: this.deliveryCity,
                        countryId: this.deliveryCountryId,
                        firstName: this.deliveryAddressFirstName,
                        lastName: this.deliveryAddressLastName,
                    };

                    if (shippingAddressId) {
                        await this.updateCustomerAddress(shippingAddressId, shippingAddressData, 'shipping');
                    } else {
                        await this.createCustomerAddress(customer.id, shippingAddressData, 'shipping');
                    }
                } else {
                    if (customer.activeBillingAddress.id !== customer.activeShippingAddress.id) {
                        await this.setDefaultAddress(customer.activeBillingAddress.id, 'shipping');
                    }
                }
            } catch (error) {
                console.error(error);

                EventBus.$emit('trigger-alert', {
                    message: this.$t('error.generic'),
                    state: 'error'
                });
            }
        },

        async register() {
            let customerData = {
                salutationId: this.salutationId,
                storefrontUrl: this.getStoreFrontUrl(),
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                acceptedDataProtection: this.acceptedDataProtection,
                billingAddress: {
                    salutationId: this.salutationId,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    street: this.street,
                    zipcode: this.zipcode,
                    city: this.city,
                    countryId: this.countryId
                },
            };

            if (this.createAccount) {
                customerData.password = this.password;
            } else {
                customerData.guest = true;
            }

            if (this.deliveryDifferentAsBilling) {
                customerData.shippingAddress = {
                    salutationId: this.salutationUndefinedId,
                    firstName: this.deliveryAddressFirstName,
                    lastName: this.deliveryAddressLastName,
                    street: this.deliveryStreet,
                    zipcode: this.deliveryZipcode,
                    city: this.deliveryCity,
                    countryId: this.deliveryCountryId
                };
            }

            if (this.phoneNumber) {
                customerData.billingAddress.phoneNumber = this.phoneNumber;
            }

            await this.api.client.invoke('registerCustomer post /account/register', customerData);
        }
    }
};
</script>
