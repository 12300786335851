<template>
    <div>
        <div v-if="isLoading" class="text-center">
            <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        </div>

        <div v-if="!isLoading" class="row g-5 g-xl-3 justify-content-between">
            <div class="mb-5">
                <h2 class="mb-2">{{ $t('checkout.complete_thank_you') }}</h2>
                <h5 class="mb-0">{{ $t('checkout.complete_order_number') }} {{ order.orderNumber }}</h5>
            </div>

            <div class="column col-12 col-xl-7">

                <div class="row g-4">
                    <div class="col-12 col-md-6">
                        <h5 class="mb-2">{{ $t('checkout.confirm_continue_your_info') }}</h5>

                        <p>
                            {{ billingAddress.firstName }} {{ billingAddress.lastName }}<br />
                            {{ billingAddress.street }}<br />
                            {{ billingAddress.zipcode }} {{ billingAddress.city }}<br />
                            {{ billingAddress.country?.name }}
                        </p>

                        <p>{{ customer.email }}</p>
                    </div>

                    <div class="col-12 col-md-6">
                        <template v-if="shippingMethod && shippingMethod !== 'shipping_dealer'">
                            <h5 class="mb-2">{{ $t('checkout.confirm_continue_delivery') }}</h5>

                            <p>
                                {{ shippingAddress.firstName }} {{ shippingAddress.lastName }}<br />
                                {{ shippingAddress.street }}<br />
                                {{ shippingAddress.zipcode }} {{ shippingAddress.city }}<br />
                                {{ shippingAddress.country?.name }}
                            </p>
                        </template>

                        <template v-if="shippingMethod && shippingMethod === 'shipping_dealer'">
                            <h5 v-if="shippingMethod === 'shipping_dealer'" class="mb-2"> {{
                                $t('checkout.confirm_continue_pickup') }}</h5>
                            <p class="small mb-1">{{ $t('checkout.confirm_continue_free_pickup_text') }}</p>

                            <p class="h5 text-dark">{{ shippingAddress.company }}</p>
                            <p>{{ shippingAddress.street }},<br />
                                {{ shippingAddress.zipcode }} {{ shippingAddress.city }}<br />
                                {{ shippingAddress.country?.name }}</p>
                        </template>
                    </div>
                </div>

                <hr class="my-4" />

                <h5 class="mb-2">{{ $t('checkout.payment_method') }}</h5>

                <div class="payment-method-inputs">
                    <div class="form-check ps-0">
                        <label class="form-check-label mx-0">
                            <img v-if="paymentMethod.media?.url" class="thumb" :src="paymentMethod.media?.url" alt="Credit card - Thumb">
                            <div>
                                <span class="name">{{ paymentMethod?.translated?.name }}</span>
                            </div>
                        </label>
                    </div>
                </div>

                <hr class="my-4" />

                <h5 class="mb-2">{{ $t('checkout.complete_products_in_order') }}</h5>

                <div class="cart-summary">
                    <div class="line labels">
                        <div class="line-col product">
                            {{ $t('checkout.cart_label_product') }}
                        </div>
                        <div class="line-col count">
                            {{ $t('checkout.cart_label_count') }}
                        </div>
                        <div class="line-col item-price">
                            {{ $t('checkout.cart_label_item_price') }}
                        </div>
                        <div class="line-col line-price">
                            {{ $t('checkout.cart_label_line_price') }}
                        </div>
                    </div>

                    <div class="line item" v-for="lineItem in lineItems" :key="lineItem.id">
                        <div class="line-col product">
                            <div class="thumb">
                                <img v-if="lineItem.type == 'product'" :src="lineItem?.payload.coverImg ? lineItem?.payload.coverImg : '/images/product-placeholder.jpg'" />
                            </div>
                            <div v-if="isVoucher(lineItem)" class="meta">
                                <p class="title">{{ lineItem?.label }}</p>
                            </div>
                            <div v-else class="meta">
                                <a v-if="lineItem?.payload.productUrl" :href="lineItem?.payload.productUrl" class="title">
                                    {{ lineItem?.label }}
                                    <span v-if="lineItem?.payload?.variant">({{ lineItem?.payload?.variant }})</span>
                                </a>
                                <p v-else class="title">
                                    {{ lineItem?.label }}
                                    <span v-if="lineItem?.payload?.variant">({{ lineItem?.payload?.variant }})</span>
                                </p>

                                <p v-if="lineItem?.payload.description" class="description mb-0">{{ lineItem?.payload?.description }}</p>
                                <small v-if="lineItem?.payload.productVoucherCodeProduct" class="info">{{ $t("checkout.cart_label_voucher") }} {{ formatVoucher(lineItem?.payload.productVoucherCodeProduct) }}</small>
                                <small v-if="showStockStatus(lineItem)" class="warning">{{ $t(`products.${lineItem.payload.stockStatus}_description`) }}</small>
                            </div>
                        </div>
                        <div class="line-col count static">
                            <span>{{ lineItem.quantity }}</span>
                        </div>
                        <div class="line-col item-price">
                            <span>&euro; {{ formatNumber(lineItem?.unitPrice) }}</span>
                        </div>
                        <div class="line-col line-price">
                            <span>&euro; {{ formatNumber(lineItem?.totalPrice) }}</span>
                        </div>
                    </div>

                </div>
            </div>

            <div class="column col-12 col-xl-4">

                <div class="sticky-top">
                    <div class="card checkout-card">
                        <div class="card-body">
                            <h3 class="mb-3">{{ $t('checkout.payment_amount_payed') }}</h3>

                            <div class="label-value-row">
                                <span class="label">{{ $t('checkout.payment_total') }}</span>
                                <span class="value fw-bold">€ {{ positionPrice }}</span>
                            </div>

                            <div class="label-value-row">
                                <span class="label">{{ $t('checkout.payment_delivery_cost') }}</span>
                                <span v-if="shippingTotal <= 0" class="value fw-bold">-</span>
                                <span v-if="shippingTotal > 0" class="value fw-bold">€ {{ formatNumber(shippingTotal)
                                    }}</span>
                            </div>

                            <hr />

                            <div class="label-value-row">
                                <span class="label">{{ $t('checkout.payment_final_total') }}</span>
                                <span class="value fw-bold">€{{ formatNumber(totalPrice) }}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import shopware from '../../mixins/shopware';
import formatters from '../../mixins/formatters';
import lineitem from '../../mixins/line-item';

export default {
    mixins: [shopware, lineitem, formatters],
    props: {
        redirect: String
    },
    data() {
        return {
            isLoading: false,
            order: {},
            customer: {},
            billingAddress: {},
            shippingAddress: {},
            shippingMethod: null,
            positionPrice: null,
            shippingTotal: null,
            totalPrice: null,
            paymentMethod: {},
            lineItems: [],
        };
    },
    created: function () {
        this.init();
    },
    methods: {
        async init() {
            this.isLoading = true;

            if (localStorage.getItem('lastOrderId') === null) {
                window.location.href = this.redirect;
            }

            await this.api.updateContext();
            await this.getOrder();

            this.isLoading = false;
        },
        
        getGTMItems() {
            return this.lineItems.map((item, index) => ({
                item_id : item.payload.productNumber  || '',
                item_name: item.label,
                item_list_name: item.payload.category?.name || (item.payload.productNumber ? 'insectenbestrijders' : 'voucher'),
                item_brand: item.payload.manufacturer?.name || "DCM",
                price: item.unitPrice,
                quantity: item.quantity,
                index: index + 1,
                currency: 'EUR',
                item_category: item.payload.category?.name || (item.payload.productNumber ? 'insectenbestrijders' : 'voucher')
            }));
        },
    
        async getOrder() {
            try {
                const response = await this.api.client.invoke('readOrder post /order', {
                    limit: 1,
                    ids: [localStorage.getItem('lastOrderId')],
                    associations: {
                        lineItems: {},
                        billingAddress: {
                            associations: {
                                country: {}
                            }
                        },
                        deliveries: {
                            associations: {
                                shippingMethod: {},
                                shippingOrderAddress: {
                                    associations: {
                                        country: {}
                                    }
                                }
                            }
                        },
                        transactions: {
                            associations: {
                                paymentMethod: {
                                    associations: {
                                        media: {}
                                    }
                                }
                            }
                        }
                    }
                });

                this.order = response.orders.elements[0];
                this.lineItems = this.order.lineItems;
                this.customer = this.order.orderCustomer;
                this.billingAddress = this.order.billingAddress;
                this.shippingAddress = this.order.deliveries[0].shippingOrderAddress;
                this.shippingMethod = this.order.deliveries[0].shippingMethod.technicalName;
                this.positionPrice = this.order.positionPrice;
                this.shippingTotal = this.order.shippingCosts.totalPrice;
                this.totalPrice = this.order.price.totalPrice;
                this.paymentMethod = this.order.transactions[0].paymentMethod;

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                   'event': 'purchase', 
                   'transaction_id':localStorage.getItem('transactionId'),
                    'order_id': this.order.orderNumber,
                    'value': this.totalPrice,
                    'items': this.getGTMItems()
                });

                localStorage.removeItem('lastOrderId');
                localStorage.removeItem('transactionId');
            } catch (error) {
                console.error(error);
                window.location.href = this.redirect;
            }
        }
    }
}
</script>
