import { Modal } from 'bootstrap'; // Import only the Modal component

document.addEventListener('DOMContentLoaded', function () {
    var confirm_cookie_name = "dcm_confirm_region_modal";

    var modalElement = document.getElementById('languagesVerifyModal');
    if(!modalElement) {
        return;
    }

    var cookies = document.cookie.split(';').map(cookie => cookie.trim());
    var languageCookie = cookies.find(cookie => cookie.startsWith(confirm_cookie_name  + '='));

    const preferredLanguages = navigator.languages;
    const primaryLanguage = (preferredLanguages?.length ? preferredLanguages[0] : '');
    const primaryIsEnglish = primaryLanguage.toLowerCase().includes('en');

    if (languageCookie || primaryIsEnglish) {
        return;
    } else {
        var modalInstance = new Modal(modalElement);
        var infoElement = null;

        if(primaryLanguage.toLowerCase().includes('nl')) {
            infoElement = modalElement.querySelector('#info_nl');
        } else if(primaryLanguage.toLowerCase().includes('de')) {
            infoElement = modalElement.querySelector('#info_de');
        } else if(primaryLanguage.toLowerCase().includes('fr')) {
            infoElement = modalElement.querySelector('#info_fr');
        } else {
            infoElement = modalElement.querySelector('#info_en');
        }

        if(infoElement) {
            infoElement.classList.remove('d-none');
        }

        modalInstance.show();

        document.cookie = confirm_cookie_name + "=modalShown; path=/; max-age=" + (60 * 60 * 24 * 365);
    }
});
