<template>
    <div>
        <div :class="{ 'd-block': customer }" style="display: none;">
            <slot></slot>
        </div>

        <div class="content-block">
            <div class="container">

                <div v-if="!customer" class="d-flex justify-content-center align-items-center">
                    <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                </div>

                <div v-if="customer" class="row g-2 g-md-3 g-lg-8 g-xl-12 column-lines-lg">
                    <div class="column col-12 col-lg-4">
                        <h3 class="mb-1">{{ $t('account.personal_info_title') }}</h3>

                        <div class="label-value">
                            <label for="textName">{{ $t('account.label_name') }}</label>
                            <p id="textName">{{ customer?.salutation?.translated?.displayName }} {{ customer?.firstName }} {{ customer?.lastName }}</p>
                        </div>

                        <div class="label-value">
                            <label for="textPhone">{{ $t('account.label_phone') }}</label>
                            <p id="textPhone">
                                <span v-if="address?.phoneNumber">{{ address?.phoneNumber }}</span>
                                <span v-else>-</span>
                            </p>
                        </div>

                        <!-- TODO: Newsletter is not availble -->
                        <!-- <div class="label-value">
                            <label for="booleanNewsletter">{{ $t('account.label_newsletter_state') }}</label>
                            <p id="booleanNewsletter">Ja</p>
                        </div> -->

                        <a href="#" class="icon-link mt-2" data-bs-toggle="modal"
                            data-bs-target="#modalEditPersonalInfo">
                            <ph-pencil-simple weight="bold" class="me-1" />
                            <span>{{ $t('account.personal_info_edit') }}</span>
                        </a>
                    </div>

                    <div class="column col-12 d-lg-none">
                        <hr />
                    </div>

                    <div class="column col-12 col-lg-4">
                        <h3 class="mb-1">{{ $t('account.address_info_title') }}</h3>

                        <div class="label-value">
                            <label for="textAddress">{{ $t('account.label_address') }}</label>
                            <p id="textAddress">
                                {{ address?.street }},<br />
                                {{ address?.zipcode }} {{ address?.city }},<br />
                                {{ address?.country?.translated?.name }}
                            </p>
                        </div>

                        <a href="#" class="icon-link mt-2" data-bs-toggle="modal" data-bs-target="#modalEditAddressInfo">
                            <ph-pencil-simple weight="bold" class="me-1" />
                            <span>{{ $t('account.address_info_edit') }}</span>
                        </a>
                    </div>

                    <div class="column col-12 d-lg-none" v-if="!customer.guest">
                        <hr />
                    </div>

                    <div class="column col-12 col-lg-4" v-if="!customer.guest">
                        <h3 class="mb-1">{{ $t('account.login_info_title') }}</h3>

                        <div class="label-value">
                            <label for="textEmail">{{ $t('account.label_email') }}</label>
                            <p id="textEmail">{{ customer?.email }}</p>
                        </div>

                        <div class="label-value">
                            <label for="textPassword">{{ $t('account.label_password') }}</label>
                            <p id="textPassword">
                                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</p>
                        </div>

                        <div class="d-flex flex-column align-items-start">
                            <a href="#" class="icon-link mt-2" data-bs-toggle="modal" data-bs-target="#modalEditEmail">
                                <ph-pencil-simple weight="bold" class="me-1" />
                                <span>{{ $t('account.email_edit') }}</span>
                            </a>

                            <a href="#" class="icon-link mt-2" data-bs-toggle="modal" data-bs-target="#modalEditPassword" @click="initPasswordModal()">
                                <ph-pencil-simple weight="bold" class="me-1" />
                                <span>{{ $t('account.password_edit') }}</span>
                            </a>

                            <a v-if="config('allowCustomerDeletion', true)" href="#" class="icon-link mt-2 text-danger" data-bs-toggle="modal" data-bs-target="#modalDeleteAccount">
                                <ph-trash weight="bold" class="me-1" />
                                <span>{{ $t('account.delete_account') }}</span>
                            </a>

                            <a :href="logout" class="icon-link mt-2 text-danger">
                                <ph-sign-out weight="bold" class="me-1" />
                                <span>{{ $t('account.logout') }}</span>
                            </a>
                        </div>
                    </div>
                </div>

                <a v-if="customer && customer.guest" :href="logout" class="icon-link mt-6 text-danger">
                    <ph-sign-out weight="bold" class="me-1" />
                    <span>{{ $t('account.end_session') }}</span>
                </a>
            </div>
        </div>


        <!-- Modals -->

        <!-- modalEditPersonalInfo -->
        <div class="modal fade" id="modalEditPersonalInfo" tabindex="-1" aria-labelledby="modalEditPersonalInfoLabel"
            aria-hidden="true" ref="modalEditPersonalInfo">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="modalEditPersonalInfoLabel">
                            {{ $t('account.personal_info_edit') }}
                        </h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onDismiss"></button>
                    </div>

                    <div class="modal-body">
                        <div class="form-style form-light custom">
                            <form :class="{ 'touched': submittedUpdatingPersonalInfo }" @submit.prevent="updatePersonalInfo" id="personalInfoForm">
                                <div class="field">
                                    <label for="inputSalutation" class="form-label">{{ $t('account.label_salutation') }}</label>

                                    <div class="form-check" v-for="salutation in salutations" :key="salutation.id">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="salutation"
                                            :id="salutation.id"
                                            :value="salutation.id"
                                            v-model="salutationId"
                                            required
                                        />
                                        <label class="form-check-label" :for="salutation.id">
                                            {{ salutation.translated.displayName }}
                                        </label>
                                    </div>
                                </div>

                                <div class="field mb-2">
                                    <label for="firstName" class="form-label">{{ $t('account.label_first_name') }}</label>
                                    <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        id="firstName"
                                        :value="firstName"
                                        @input="firstName = $event.target.value"
                                        required
                                    />
                                </div>

                                <div class="field mb-2">
                                    <label for="lastName" class="form-label">{{ $t('account.label_last_name') }}</label>
                                    <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        id="lastName"
                                        :value="lastName"
                                        @input="lastName = $event.target.value"
                                        required
                                    />
                                </div>

                                <div class="field mb-2">
                                    <label for="phone" class="form-label">{{ $t('account.label_phone') }}</label>
                                    <input
                                        type="tel"
                                        class="form-control form-control-lg"
                                        id="phone"
                                        :value="phoneNumber"
                                        @input="phoneNumber = $event.target.value"
                                        pattern="^[0-9\s\-\(\)\.]+$"
                                        :required="config('phoneNumberFieldRequired', false)"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal"  @click="onDismiss">
                            {{ $t('account.btn_cancel') }}
                        </button>

                        <button type="submit" form="personalInfoForm" class="btn btn-primary position-relative" @click="submittedUpdatingPersonalInfo = true">
                            <div class="position-absolute top-50 start-50 translate-middle" :class="{ 'd-none': !isUpdatingPersonalInfo }">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>

                            <div class="d-flex align-items-center" :class="{ 'opacity-0': isUpdatingPersonalInfo }">
                                <p class="mb-0">{{ $t('account.btn_edit') }}</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End modalEditPersonalInfo -->

        <!-- modalEditAddressInfo -->
        <div class="modal fade" id="modalEditAddressInfo" tabindex="-1" aria-labelledby="modalEditAddressInfoLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="modalEditAddressInfoLabel">{{ $t('account.address_info_edit') }}
                        </h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onDismiss"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-style form-light custom">
                            <form :class="{ 'touched': submittedUpdatingAddressInfo }" @submit.prevent="updateAddressInfo" id="addressInfoForm">
                                <div class="field mb-2">
                                    <label for="street" class="form-label">{{ $t('account.label_street') }}</label>
                                    <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        id="street"
                                        :value="street"
                                        @input="street = $event.target.value"
                                        pattern="^(.+)\s(\d+(\s*[^\d\s]+)*)$"
                                        required
                                    />
                                </div>

                                <div class="field mb-2">
                                    <label for="zipcode" class="form-label">{{ $t('account.label_zip') }}</label>
                                    <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        id="zipcode"
                                        :value="zipcode"
                                        @input="zipcode = $event.target.value"
                                        :required="countryConfig('postalCodeRequired', true)"
                                        :pattern="postalCodePattern"
                                    />
                                </div>

                                <div class="field mb-2">
                                    <label for="city" class="form-label">{{ $t('account.label_city') }}</label>
                                    <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        id="city"
                                        :value="city"
                                        @input="city = $event.target.value"
                                        required
                                    />
                                </div>

                                <div :class="['field mb-2', countries.length <= 1 ? 'd-none' : '']">
                                    <label for="inputCountry" class="form-label">{{ $t('account.label_country') }}</label>
                                    <select
                                        class="form-select form-select-lg"
                                        id="inputCountry"
                                        v-model="countryId"
                                        required
                                    >
                                        <option v-for="country in countries" :key="country.id" :value="country.id">
                                            {{ country.translated.name }}
                                        </option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="onDismiss">
                            {{ $t('account.btn_cancel') }}
                        </button>

                        <button type="submit" form="addressInfoForm" class="btn btn-primary position-relative" @click="submittedUpdatingAddressInfo = true">
                            <div class="position-absolute top-50 start-50 translate-middle"
                                :class="{ 'd-none': !isUpdatingAddressInfo }">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>

                            <div class="d-flex align-items-center" :class="{ 'opacity-0': isUpdatingAddressInfo }">
                                <p class="mb-0">{{ $t('account.btn_edit') }}</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End modalEditAddressInfo -->

        <!-- modalEditEmail -->
        <div class="modal fade" id="modalEditEmail" tabindex="-1" aria-labelledby="modalEditEmailLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="modalEditEmailLabel">{{ $t('account.email_edit') }}</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onDismiss"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-style form-light custom">
                            <form :class="{ 'touched': submittedUpdatingEmail }" @submit.prevent="updateEmail" id="emailForm">
                                <div class="field mb-2">
                                    <label for="editEmail" class="form-label">{{ $t('account.label_email') }}</label>
                                    <input
                                        type="email"
                                        class="form-control form-control-lg"
                                        id="editEmail"
                                        :value="email"
                                        @input="email = $event.target.value"
                                        required
                                    />
                                </div>

                                <div :class="['field mb-2', emailsMatchInvalid ? 'required' : '']">
                                    <label for="editEmailConfirmation" class="form-label">{{ $t('account.label_email_confirmation') }}</label>
                                    <input
                                        type="email"
                                        class="form-control form-control-lg"
                                        id="editEmailConfirmation"
                                        :value="emailConfirmation"
                                        @input="emailConfirmation = $event.target.value"
                                        required
                                    />

                                    <span v-if="emailsMatchInvalid" class="error-message small text-danger">
                                        {{ $t('error.emails_must_match') }}
                                    </span>
                                </div>

                                <div class="field">
                                    <label for="editEmailPassword" class="form-label">{{ $t('account.label_password') }}</label>
                                    <input
                                        type="password"
                                        class="form-control form-control-lg"
                                        id="editEmailPassword"
                                        v-model="password"
                                        :minlength="passwordMinLength"
                                        autocomplete="current-password"
                                        required
                                    />
                                </div>

                                <!-- BE errors  -->
                                <div v-if="beErrors?.length > 0" class="error-message small text-danger">
                                    <ul class="list-unstyled">
                                        <li v-for="error in beErrors">
                                            <p class="small text-danger">{{ error }}</p>
                                        </li>
                                    </ul>
                                </div>
                                <!-- end-BE errors -->
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="onDismiss">{{ $t('account.btn_cancel') }}</button>
                        <button type="submit" form="emailForm" class="btn btn-primary position-relative" @click="submittedUpdatingEmail = true">
                            <div class="position-absolute top-50 start-50 translate-middle" :class="{ 'd-none': !isUpdatingEmail }">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>

                            <div class="d-flex align-items-center" :class="{ 'opacity-0': isUpdatingEmail }">
                                <p class="mb-0">{{ $t('account.btn_edit') }}</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End modalEditEmail -->

        <!-- modalEditPassword -->
        <div class="modal fade" id="modalEditPassword" tabindex="-1" aria-labelledby="modalEditPasswordLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="modalEditPasswordLabel">{{ $t('account.password_edit') }}</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onDismiss"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-style form-light custom">
                            <form :class="{ 'touched': submittedUpdatingPassword }" @submit.prevent="updatePassword" id="passwordForm">
                                <div class="field mb-2">
                                    <label for="inputPassword" class="form-label">{{ $t('account.label_password') }}</label>
                                    <input
                                        type="password"
                                        class="form-control form-control-lg"
                                        id="inputPassword"
                                        v-model="password"
                                        :minlength="passwordMinLength"
                                        autocomplete="current-password"
                                        required
                                    />
                                </div>

                                <div :class="['field', newPasswordsMatchInvalid ? 'required' : '']">
                                    <label for="inputNewPassword" class="form-label">{{ $t('account.label_new_password') }}</label>
                                    <input
                                        type="password"
                                        class="form-control form-control-lg"
                                        id="inputNewPassword"
                                        v-model="newPassword"
                                        :minlength="passwordMinLength"
                                        autocomplete="new-password"
                                        required
                                    />
                                </div>

                                <div :class="['field', newPasswordsMatchInvalid ? 'required' : '']">
                                    <label for="inputNewPasswordConfirmation" class="form-label">{{ $t('account.label_new_password_confirmation') }}</label>
                                    <input
                                        type="password"
                                        class="form-control form-control-lg"
                                        id="inputNewPasswordConfirmation"
                                        v-model="newPasswordConfirmation"
                                        :minlength="passwordMinLength"
                                        autocomplete="new-password"
                                        required
                                    />

                                    <span v-if="newPasswordsMatchInvalid" class="error-message small text-danger">
                                        {{ $t('error.passwords_must_match') }}
                                    </span>
                                </div>

                                <!-- BE errors  -->
                                <div v-if="beErrors?.length > 0" class="error-message small text-danger">
                                    <ul class="list-unstyled">
                                        <li v-for="error in beErrors">
                                            <p class="small text-danger">{{ error }}</p>
                                        </li>
                                    </ul>
                                </div>
                                <!-- end-BE errors -->
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="onDismiss">
                            {{ $t('account.btn_cancel') }}
                        </button>

                        <button type="submit" form="passwordForm" class="btn btn-primary position-relative" @click="submittedUpdatingPassword = true">
                            <div class="position-absolute top-50 start-50 translate-middle" :class="{ 'd-none': !isUpdatingPassword }">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>

                            <div class="d-flex align-items-center" :class="{ 'opacity-0': isUpdatingPassword }">
                                <p class="mb-0">{{ $t('account.btn_edit') }}</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End modalEditPassword -->

        <!-- modalDeleteAccount -->
        <div class="modal fade" id="modalDeleteAccount" tabindex="-1" aria-labelledby="modalDeleteAccountLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="modalDeleteAccountLabel">{{ $t('account.delete_account') }}</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onDismiss"></button>
                    </div>
                    <div class="modal-body">
                        <h4>{{ $t('account.delete_account_confirm_title') }}</h4>
                        <p>{{ $t('account.delete_account_confirm_text') }}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="onDismiss">
                            {{ $t('account.btn_cancel') }}
                        </button>

                        <button type="button" @click="deleteAccount()" class="btn btn-primary position-relative">
                            <div class="position-absolute top-50 start-50 translate-middle" :class="{ 'd-none': !isDeletingAccount }">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>

                            <div class="d-flex align-items-center" :class="{ 'opacity-0': isDeletingAccount }">
                                <p class="mb-0">{{ $t('account.btn_delete_permanently') }}</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End modalDeleteAccount -->

        <!-- End Modals -->
    </div>
</template>

<script lang="ts">
import shopware from '../../mixins/shopware';
import customer from '../../mixins/customer';

import { PhPencilSimple, PhSignOut, PhTrash } from 'phosphor-vue';
import { Modal } from 'bootstrap';
import { EventBus } from '../../api/eventBus.ts';

export default {
    name: 'MyAccount',
    components: { PhPencilSimple, PhSignOut, PhTrash },
    mixins: [shopware, customer],
    props: {
        redirect: String,
        logout: String
    },

    data() {
        return {
            // fields
            customer: null,
            firstName: null,
            lastName: null,
            salutationId: null,
            phoneNumber: null,
            street: null,
            zipcode: null,
            city: null,
            countryId: null,
            email: null,
            emailConfirmation: null,
            password: null,
            newPassword: null,
            newPasswordConfirmation: null,

            // api
            beErrors: [],

            // loaders + state
            submittedUpdatingPersonalInfo: false,
            isUpdatingPersonalInfo: false,
            submittedUpdatingAddressInfo: false,
            isUpdatingAddressInfo: false,
            submittedUpdatingEmail: false,
            isUpdatingEmail: false,
            submittedUpdatingPassword: false,
            isUpdatingPassword: false,
            isDeletingAccount: false,

            // modals
            personalInfoModal: null,
            addressInfoModal: null,
            emailModal: null,
            passwordModal: null,
            deleteAccountModal: null
        }
    },
    created: function () {
        this.init();
    },
    mounted() {
        this.personalInfoModal = new Modal(document.getElementById('modalEditPersonalInfo'));
        this.addressInfoModal = new Modal(document.getElementById('modalEditAddressInfo'));
        this.emailModal = new Modal(document.getElementById('modalEditEmail'));
        this.passwordModal = new Modal(document.getElementById('modalEditPassword'));
        this.deleteAccountModal = new Modal(document.getElementById('modalDeleteAccount'));
    },
    computed: {
        selectedCountry() {
            if (!this.countryId) return;

            return this.countries.find(country => country.id === this.countryId);
        },
        postalCodePattern() {
            if (this.countryConfig('checkAdvancedPostalCodePattern') && this.countryConfig('advancedPostalCodePattern')) {
                return this.countryConfig('advancedPostalCodePattern');
            }

            if (this.countryConfig('checkPostalCodePattern') && this.countryConfig('defaultPostalCodePattern')) {
                return this.countryConfig('defaultPostalCodePattern');
            }

            return '(.*?)';
        },
        passwordMinLength() {
            return this.config('passwordMinLength', 8);
        },
        emailsMatchInvalid() {
            return this.email && this.emailConfirmation && this.email !== this.emailConfirmation;
        },
        newPasswordLengthInvalid() {
            return this.newPassword && this.newPassword.length < this.passwordMinLength;
        },
        newPasswordsMatchInvalid() {
            return this.newPassword && this.newPasswordConfirmation && this.newPassword !== this.newPasswordConfirmation;
        }
    },
    methods: {
        async init() {
            await this.getContext();

            const promises = [
                this.getCustomer(),
                this.getSalutations(),
                this.getCountries()
            ];
            await Promise.all(promises);
        },

        initPasswordModal() {
            this.password = null;
            this.newPassword = null;
            this.newPasswordConfirmation = null;
        },

        async getCustomer() {
            try {
                const data = await Promise.all([
                    this.api.client.invoke("readCustomer post /account/customer", {
                        associations: {
                            salutation: {},
                        }
                    }),
                    this.api.client.invoke("listAddress post /account/list-address", {}),
                ]);

                this.customer = data[0];
                this.address = data[1].elements[0];

                // init edit personal info modal
                this.salutationId = this.customer?.salutationId;
                this.firstName = this.customer?.firstName;
                this.lastName = this.customer?.lastName;
                this.phoneNumber = this.address?.phoneNumber;

                // init edit address info modal
                this.street = this.address?.street;
                this.zipcode = this.address?.zipcode;
                this.city = this.address?.city;
                this.countryId = this.address?.countryId;
            }
            catch (error) {
                if (error.status === 403) {
                    window.location.href = this.redirect;
                }
            }
        },

        async updatePersonalInfo() {
            if (this.firstName === this.customer?.firstName && this.lastName === this.customer?.lastName && this.phoneNumber === this.address?.phoneNumber && this.salutationId === this.customer?.salutationId) {
                this.personalInfoModal.hide();
                this.onDismiss();
                return;
            }

            try {
                this.isUpdatingPersonalInfo = true;
                this.submittedUpdatingPersonalInfo = true;

                if (this.firstName !== this.customer?.firstName || this.lastName !== this.customer?.lastName || this.salutationId !== this.customer?.salutationId) {
                    const { firstName, lastName, salutationId } = this;

                    const update = await this.updateCustomerProfile({
                        firstName,
                        lastName,
                        salutationId
                    });

                    if (update.success) {
                        this.customer.firstName = firstName;
                        this.customer.lastName = lastName;
                        this.customer.salutationId = salutationId;
                        this.customer.salutation.translated.displayName = this.salutations.find(salutation => salutation.id === salutationId)?.translated.displayName;
                    }
                }

                await this.changeAddress();

                this.personalInfoModal.hide();
                this.isUpdatingPersonalInfo = false;
                this.onDismiss();

                EventBus.$emit('trigger-alert', {
                    message: this.$t('messages.changed_personal_information'),
                    state: 'success'
                });
            } finally {
                this.isUpdatingPersonalInfo = false;
            }
        },

        async updateAddressInfo() {
            if (this.street === this.address?.street && this.zipcode === this.address?.zipcode && this.city === this.address?.city && this.countryId === this.address?.countryId && this.phoneNumber === this.address?.phoneNumber) {
                this.addressInfoModal.hide();
                this.onDismiss();
                return;
            }

            this.isUpdatingAddressInfo = true;
            this.submittedUpdatingAddressInfo = true;

            await this.changeAddress();

            this.addressInfoModal.hide();
            this.onDismiss();
            this.isUpdatingAddressInfo = false;

            EventBus.$emit('trigger-alert', {
                message: this.$t('messages.changed_address'),
                state: 'success'
            });
        },

        async changeAddress() {
            try {
                await this.updateCustomerAddress(this.address.id, {
                    street: this.street,
                    zipcode: this.zipcode,
                    city: this.city,
                    countryId: this.countryId,
                    salutationId: this.salutationId,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phoneNumber: this.phoneNumber
                });

                const address = await this.api.client.invoke("listAddress post /account/list-address", {});
                this.address = address.elements[0];
            } catch (error) {
                console.error(error);

                EventBus.$emit('trigger-alert', {
                    message: this.$t('error.generic'),
                    state: 'error'
                });
            }
        },

        async updateEmail() {
            try {
                this.isUpdatingEmail = true;
                this.submittedUpdatingEmail = true;

                const update = await this.api.client.invoke("changeEmail post /account/change-email", {
                    email: this.email,
                    emailConfirmation: this.emailConfirmation,
                    password: this.password
                });

                if (update.success) {
                    this.customer.email = this.email;
                    this.emailModal.hide();
                    this.onDismiss();

                    EventBus.$emit('trigger-alert', {
                        message: this.$t('messages.changed_email'),
                        state: 'success'
                    });
                }
            } catch (error) {
                this.beErrors = this.getErrorMessages(error);
            } finally {
                this.isUpdatingEmail = false;
            }
        },

        async updatePassword() {
            try {
                this.isUpdatingPassword = true;
                this.submittedUpdatingPassword = true;

                await this.api.client.invoke("changePassword post /account/change-password", {
                    password: this.password,
                    newPassword: this.newPassword,
                    newPasswordConfirm: this.newPasswordConfirmation
                });

                this.initPasswordModal();
                this.passwordModal.hide();
                this.onDismiss();

                EventBus.$emit('trigger-alert', {
                    message: this.$t('messages.changed_password'),
                    state: 'success'
                });
            } catch (error) {
                this.beErrors = this.getErrorMessages(error);
            } finally {
                this.isUpdatingPassword = false;
            }
        },

        async deleteAccount() {
            try {
                this.isDeletingAccount = true;

                await this.api.client.invoke("deleteCustomer delete /account/customer");

                this.deleteAccountModal.hide();

                EventBus.$emit('trigger-alert', {
                    message: this.$t('messages.delete_account_success'),
                    state: 'success'
                });

                window.location.href = this.redirect;
            } catch (error) {
                EventBus.$emit('trigger-alert', {
                    message: this.$t('error.generic'),
                    state: 'error'
                });
            } finally {
                this.isDeletingAccount = false;
            }
        },

        countryConfig(key, fallback = null) {
            const configuration = this.selectedCountry;

            if (configuration && key in configuration) {
                return configuration[key];
            } else {
                return fallback;
            }
        },

        onDismiss() {
            this.beErrors = [];

            this.submittedUpdatingPersonalInfo = false;
            this.submittedUpdatingAddressInfo = false;
            this.submittedUpdatingEmail = false;
            this.submittedUpdatingPassword = false;
        }
    }
}

</script>
