import { Tooltip } from 'bootstrap';

const TooltipDirective = {
    bind(el, binding) {
        const setupTooltip = () => {
            if (binding.value) {
                el.setAttribute('title', binding.value);
                el._tooltipInstance = new Tooltip(el);
            } else {
                if (el._tooltipInstance) {
                    el._tooltipInstance.dispose();
                    el._tooltipInstance = null;
                }
                el.removeAttribute('title');
            }
        };

        setupTooltip();
    },
    update(el, binding) {
        if (binding.oldValue !== binding.value) {
            const setupTooltip = () => {
                if (binding.value) {
                    el.setAttribute('title', binding.value);
                    el._tooltipInstance = new Tooltip(el);
                } else {
                    if (el._tooltipInstance) {
                        el._tooltipInstance.dispose();
                        el._tooltipInstance = null;
                    }
                    el.removeAttribute('title');
                }
            };

            setupTooltip();
        }
    },
    unbind(el) {
        if (el._tooltipInstance) {
            el._tooltipInstance.dispose();
            el._tooltipInstance = null;
        }
    }
};

export default TooltipDirective;
