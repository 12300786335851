<template>
    <div id="dealer-selector" class="container">
        <div class="mb-4" v-if="pickup">
            <h2 class="mb-2">{{ $t('checkout.pickup_point_title')}}</h2>
            <p>{{ $t('checkout.pickup_point_text') }}</p>
        </div>
        <div class="mb-4" v-else>
            <h2 class="mb-2">{{ $t('checkout.service_point_title')}}</h2>
            <p>{{ $t('checkout.service_point_text') }}</p>
        </div>

        <div class="row g-3 justify-content-between">

            <div class="column col-12 col-xl-8">
                <div class="row g-3">
                    <div class="col-12 col-lg-6">

                        <div class="form-style form-light custom">
                            <div class="field" :class="{ 'current-location-active': isCurrentLocation }">
                                <label for="inputDealerSearch" class="form-label">{{ $t('dealers.postal_code_or_city') }}</label>
                                <gmap-autocomplete
                                    :componentRestrictions="countryRestrictions"
                                    :setFieldsTo="['geometry','formatted_address']"
                                    @place_changed="getLocationCoords"
                                >
                                    <input
                                        class="form-control form-control-lg"
                                        id="inputDealerSearch"
                                        type="search"
                                        ref="input"
                                        :placeholder="isCurrentLocation ? $t('dealers.postal_code_or_city_placeholder_current') : $t('dealers.postal_code_or_city_placeholder_default')"
                                        v-bind="$attrs"
                                        v-on="$listeners"
                                    />
                                </gmap-autocomplete>

                                <a v-if="geolocationSupported" href="#" class="use-location" @click.prevent="getCurrentLocationCoords">
                                    <ph-crosshair />
                                    <span>{{ $t('dealers.use_current_location') }}</span>
                                </a>
                            </div>
                        </div>

                        <div class="pickup-list">
                            <div class="form-check" v-for="d in dealers" :key="d.identifier">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="pickupLocation"
                                    :id="'radioLocation-' + d.identifier"
                                    :value="d"
                                    v-model="dealer"
                                    @change="selectDealer()"
                                />
                                <label class="form-check-label" :for="'radioLocation-' + d.identifier">
                                    <span class="name">{{ d.translated.name }}</span>
                                    <span class="address">{{ d.address }}, {{ d.zipcode }} {{ d.city }}</span>
                                </label>
                            </div>

                            <p class="dealers-empty" v-if="dealers.length == 0">
                                {{ $t('dealers.map_empty_results') }}
                            </p>
                        </div>

                    </div>
                    <div class="col-12 col-lg-6">
                        <gmap-map
                            v-if="hasConsent"
                            class="google-map"
                            ref="map"
                            :center="mapCenter"
                            :zoom="mapZoom"
                            :options="mapOptions"
                        >
                            <gmap-marker
                                v-for="(m, index) in markers"
                                :key="index"
                                :position="m.coords"
                                :clickable="true"
                                :icon="m.icon"
                                @click="showMarkerDetails(m)"
                            />

                            <gmap-info-window
                                v-if="dealer"
                                :options="infoWindowOptions"
                                :position="infoWindowPos"
                                :opened="infoWindowOpen"
                                @closeclick="infoWindowOpen = false"
                            >
                                <dealer-item :dealer="dealer" />
                            </gmap-info-window>
                        </gmap-map>

                        <div class="card checkout-card mt-3 mt-xl-4" v-if="hasOpeningHours(dealer)">
                            <div class="card-body">
                                <div :class="{ 'mb-4': dealer.closing_days }">
                                    <h4 class="mb-3">{{ $t('dealers.opening_hours_title') }}</h4>

                                    <div class="label-value-row" v-for="weekday in ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']">
                                        <span class="label">{{ $t(`dealers.opening_hours_${weekday}`) }}</span>
                                        <span class="value" :class="{ 'text-muted': isClosed(dealer, weekday) }">
                                            {{ getOpeningHours(dealer, weekday) }}
                                        </span>
                                    </div>
                                </div>

                                <div v-if="dealer.closing_days">
                                    <h4 class="mb-3">{{ $t('dealers.closing_days_title') }}</h4>

                                    <div class="label-value-row" v-for="range in dealer.closing_days.transformed">
                                        <span class="label" v-if="range.start && range.end">{{ formattedDate(range.start) }} {{ $t('dealers.closing_days_until') }} {{ formattedDate(range.end) }}</span>
                                        <span class="label" v-else>{{ formattedDate(range.start || range.end) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column col-12 col-xl-4">
                <div class="sticky-top">
                    <cart-summary :redirect="redirect"></cart-summary>
                    <a class="btn btn-secondary w-100 mt-1" :class="{ 'disabled': !dealer }" :href="nextStep" @click.prevent="dealerToCart()">
                        {{ $t('checkout.btn_next_step')}}
                    </a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { PhCrosshair } from 'phosphor-vue';

import shopware from '../../mixins/shopware';
import dealer from '../../mixins/dealer';

export default {
    components: {
        PhCrosshair,
    },
    mixins: [shopware, dealer],
    props: {
        redirect: String,
        nextStep: String
    },
    data() {
        return {
            cart: null,
            pickup: null,
            locationDistance: 10
        };
    },
    computed: {
        filters() {
            return { type: (this.pickup) ? 'naturapypickup' : 'naturapy' }
        }
    },
    async created() {
        this.init();
    },
    methods: {
        async init() {
            await this.initContext();
            this.initMap();

            const promises = [
                this.getCart()
            ];

            await Promise.all(promises);

            // Define if the dealer should be selected as service or pickup point
            this.pickup = (this.context.shippingMethod.technicalName === 'shipping_dealer');

            // Use zipcode from customer address as default
            const shippingAddress = this.context.customer.activeShippingAddress;
            const defaultInput = `${shippingAddress.zipcode} ${shippingAddress.city}, ${shippingAddress.country.translated.name}`;

            if (defaultInput) {
                this.$refs.input.value = defaultInput;
                this.getAddressStringCoordinates(defaultInput);
            } else {
                this.doSearch(this.filters);
            }
        },

        setLocationCoords(lat, lng) {
            this.locationCoords.lat = lat;
            this.locationCoords.lng = lng;

            this.doSearch(this.filters);
        },

        selectDealer() {
            this.triggerReferredToDealerEvent();

            this.showDealerDetails(this.dealer, false)
        },

        triggerReferredToDealerEvent() {
            if (this.cart && this.cart.lineItems.length > 0) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'referred_to_dealer',
                    'transaction_id': this.cart.token,
                    'dealer_id': this.dealer.identifier,
                    'dealer_name': this.dealer.name,
                    'tax': this.cart.price.totalPrice - this.cart.price.netPrice,
                    'value': this.cart.price.totalPrice,
                    'items': this.getGTMItems()
                });
            }
        },

        getGTMItems() {
            return this.cart.lineItems.map((item, index) => ({
                item_id: item.payload.productNumber || '',
                item_name: item.label,
                item_list_name: item.payload.category?.name || (item.payload.productNumber ? 'insectenbestrijders' : 'voucher'),
                item_brand: item.payload.manufacturer?.name || "DCM",
                price: item.price.unitPrice,
                quantity: item.quantity,
                index: index + 1,
                currency: 'EUR',
                item_category: item.payload.category?.name || (item.payload.productNumber ? 'insectenbestrijders' : 'voucher')
            }));
        },

        async dealerToCart() {
            let endpoint = '/checkout/cart/dealer';
            let params = {
                token: this.context.token,
                customer: this.context.customer.id,
                dealer: this.dealer.id
            };

            try {
                this.isLoading = true;

                const result = await this.api.client.invoke(`dealerToCart patch ${endpoint}`, params);
                window.location = this.nextStep;
            } catch (error) {
                console.error(error);
            }

            this.isLoading = false;
        },

        async getCart() {
            try {
                this.cart = await this.api.client.invoke("readCart get /checkout/cart");
            } catch (error) {
                console.error(error);
            }
        },
    }
};
</script>
